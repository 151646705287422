/**
 * Компонент `Promotion` для отображения слайдера с промо-слайдами.
 *
 * Этот компонент использует библиотеку Swiper для создания карусели слайдов.
 * Слайды загружаются из `slidesPromo`, и для каждого слайда создается компонент `SlidePromo`.
 * Компонент поддерживает адаптивность и автопроигрывание слайдов.
 *
 * @component
 */

import { Swiper } from '../elements/Swiper';
import { SwiperSlide } from '../elements/SwiperSlide';
import { slidesPromo } from '../../data/slides';
import SlidePromo from '../elements/SlidePromo';

export default function Promotion() {
  return (
    <section className="section section--promotion">
      <div className="container">
        <div className="section__head">
          <h2 className="heading-secondary">Лечиться с нами выгодно</h2>
          <div className="swiper-pagination" id="promo-slider-pagination"></div>
        </div>

        <div className="section__slider">
          <Swiper
            id="promo-slider"
            loop="true"
            spaceBetween={40}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
            breakpoints={{
              0: {
                slidesPerView: 1
              },
              1366: {
                slidesPerView: 2
              }
            }}
            pagination={{
              el: '#promo-slider-pagination',
              type: 'bullets',
              clickable: true
            }}
          >
            {slidesPromo.map(s => (
              <SwiperSlide key={s.id}>
                <SlidePromo slide={s} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}