/**
 * Компонент для отображения дополнительных данных в разделе Контакты.
 *
 * Этот компонент перебирает массив `contactsRules`, полученный из '../../data/contacts',
 * и каждый элемент массива отрисовывает с помощью компонента `СontactsRule`.
 *
 * @component
 */

import { contactsRules } from '../../data/contacts';
import СontactsRule from '../elements/СontactsRule';

export default function СontactsRules() {
  return (
    <div className="contact-page">
      {contactsRules.map(r => (
        <div className="contact-page__section" key={r.id}>
          <СontactsRule rule={r} />          
        </div>
      ))}
    </div>
  );
}