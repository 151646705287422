import { v4 as uuidv4 } from 'uuid';
import avatar1 from '../assets/img/avatars/avatar-doctor-1.png';
import avatar2 from '../assets/img/avatars/avatar-doctor-5.png';
import avatar3 from '../assets/img/avatars/avatar-doctor-3.png';

export const reviews = [
  {
    doctor: 'Тен Сергее Хончеловиче',
    avatar: avatar1,
    text: 'Сергей Хончелович действительно очень хороший врач, специалист своего дела. Очень внимательный и компетентный врач. Клиника оснащена современным оборудованием, внимательный персонал. Я очень часто посещаю врачей и это лучший специалист в своём деле.',
    rating: 4.5,
    phone: '+7-924-21XXXXX',
    id: uuidv4(),
  },
  {
    doctor: 'Новиковой Елене Викторовне',
    avatar: avatar2,
    text: 'Елена Викторовна мой стоматолог, которому я доверяю и точно знаю, что мои зубы она пролечит качественно, сделает всё возможное. Ранее я ходила к опытному стоматологу в поликлинику, но там отношение было такое, что никакого лечения не захочешь. Со Скаредновой у нас полное взаимопонимание, мне нравится её манера работы. Доктор отзывчива, комментирует свои действия. Она аккуратна в работе, не причиняет боли во время лечения.',
    rating: 4.5,
    phone: '+7-909-85XXXXX',
    link: 'https://prodoctorov.ru/habarovsk/vrach/252482-skarednova/#otzivi',
    id: uuidv4(),
  },
  {
    doctor: 'Тен Марине Эдуардовне',
    avatar: avatar3,
    text: 'Делала несколько коронок у Марины Эдуардовны. Ношу их уже несколько лет и уже не могу отличить где мои зубы, а где коронки. Очень благодарна Марине за комфортное лечение и качественный результат. Никаких проблем с коронками.',
    rating: 4.5,
    phone: '+7-924-21XXXXX',
    id: uuidv4(),
  },
];