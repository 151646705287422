import React from 'react';
import ReactDOM from 'react-dom/client';
import { Link, RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'normalize.css';
import 'swiper/scss';
import 'react-responsive-modal/styles.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MainPage from './components/pages/MainPage';
import DoctorsPage from './components/pages/DoctorsPage';
import DoctorPage from './components/pages/DoctorPage';
import DocumentsPage from './components/pages/DocumentsPage';
import СontactsPage from './components/pages/СontactsPage';
import ErrorPage from './components/pages/ErrorPage';
import { doctors } from './data/doctors';

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: '/',
        element: <MainPage />,
      },
      {
        path: '/doctors',
        handle: { crumb: () => <Link to="/doctors" className="breadcrumbs__link">Специалисты</Link> },
        children: [
          {
            index: true,
            element: <DoctorsPage />,
          },
          {
            path: '/doctors/:url',
            element: <DoctorPage />,
            loader: ({ params }) => params.url,
            handle: { crumb: (url) => {
              const doctor = doctors.find(d => d.url === url);
              return (
                <span>{doctor.surname + ' ' + doctor.name}</span>
              );
            } },
          },
        ],
      },
      {
        path: '/documents',
        element: <DocumentsPage />,
      },
      {
        path: '/contacts',
        element: <СontactsPage />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();