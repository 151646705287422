import { v4 as uuidv4 } from 'uuid';
import promo1 from '../assets/img/promo-slide-1.jpg';
import promo2 from '../assets/img/promo-slide-2.jpg';
import promo3 from '../assets/img/promo-slide-3.jpg';
import promo4 from '../assets/img/promo-slide-4.jpg';

export const slidesHero = [
  {
    title: 'Трёхмерная диагностика зубов сканером 3D Shape',
    desc: 'Высокоточный 3D сканер для создания трехмерных моделей по зубным слепкам и моделям из гипса',
    id: uuidv4(),
  },
  {
    title: 'Трёхмерная диагностика зубов сканером 3D Shape',
    desc: 'Высокоточный 3D сканер для создания трехмерных моделей по зубным слепкам и моделям из гипса',
    id: uuidv4(),
  },
  {
    title: 'Трёхмерная диагностика зубов сканером 3D Shape',
    desc: 'Высокоточный 3D сканер для создания трехмерных моделей по зубным слепкам и моделям из гипса',
    id: uuidv4(),
  },
];

export const slidesPromo = [
  {
    title: 'Установка световой пломбы',
    price: '5.000',
    img: promo1,
    id: uuidv4(),
  },
  {
    title: 'Установка импланта Osstem',
    price: '20.000',
    img: promo2,
    id: uuidv4(),
  },
  {
    title: 'Установка брекет-системы',
    price: 'от 75.000',
    img: promo3,
    id: uuidv4(),
  },
  {
    title: 'Осмотр профильного специалиста',
    price: 'БЕСПЛАТНО',
    img: promo4,
    id: uuidv4(),
  },
];