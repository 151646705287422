/**
 * Компонент `Registration` для отображения списка регистрационных документов.
 *
 * Этот компонент использует массив `registrations`, импортированный из '../../data/documents',
 * для отображения каждого регистрационного документа с помощью компонента `RegistrationItem`.
 *
 * @component
 */

import { registrations } from '../../data/documents';
import RegistrationItem from '../elements/RegistrationItem';

export default function Registration() {
  return (
    <div className="container">
      <div className="section-hero__head">
        <h1 className="section-hero__title" id="registration">Документы и правила внутреннего распорядка</h1>
      </div>

      <div className="company-details">
        {registrations.map(r => (
          <RegistrationItem reg={r} key={r.id} />
        ))}
      </div>
    </div>
  );
}