/**
 * Компонент `SlidePromo` для отображения промо-слайда с изображением, заголовком и ценой.
 *
 * @component
 * @param {Object} slide - Объект со свойствами слайда.
 * @param {string} slide.img - URL изображения слайда.
 * @param {string} slide.title - Заголовок слайда.
 * @param {string} slide.price - Цена, отображаемая на слайде.
 */

export default function SlidePromo({ slide }) {
  return (
    <div className="promo-slide">
      <div
        className="promo-slide__img"
        style={{
          backgroundImage: `url(${slide.img})`
        }}
      ></div>
      <h5 className="promo-slide__title">{slide.title}</h5>
      <div className="promo-slide__price">{slide.price}</div>
    </div>
  );
}