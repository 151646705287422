/**
 * Компонент `Swiper` для создания слайдера с возможностью настройки через параметры.
 *
 * Использует `useEffect` для инициализации Swiper с переданными параметрами.
 * Компонент принимает дочерние элементы, которые будут отображаться внутри слайдера.
 *
 * @component
 * @param {ReactNode} children - Дочерние элементы, которые будут отображаться в слайдере.
 * @param {Object} rest - Дополнительные параметры для настройки Swiper.
 */

import { useEffect, useRef } from 'react';
import { register } from 'swiper/element/bundle';

export function Swiper({ children, ...rest }) {
  const swiperRef = useRef(null);
  
  useEffect(() => {
    register();

    const params = { ...rest };

    Object.assign(swiperRef.current, params);

    swiperRef.current.initialize();
  }, [rest]);

  return (
    <swiper-container init="false" ref={swiperRef}>
      {children}
    </swiper-container>
  );
}