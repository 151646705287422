/**
 * Компонент для отображения карты с использованием Yandex Maps.
 *
 * @component
 * @param {string} type - Класс CSS, который будет применен к карте.
 * @remarks
 * Компонент использует библиотеку `@pbe/react-yandex-maps` для интеграции с Yandex Maps.
 * Отображает карту с центром в заданных координатах и маркером в точке с заданными свойствами.
 *
 * @see {@link https://yandex.com/dev/maps/ | Yandex Maps API}
 */

import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';

export default function MapScheme({ type }) {
  return (
    <YMaps
      query={{
        apikey: 'c99b8480-88cd-4aec-a040-ffffad7183cf',
        lang: 'ru_RU',
      }}
    >
      <Map
        className={type}
        defaultState={{
          center: [48.46264957390594, 135.08964050000003],
          zoom: 17,
          controls: []
        }}
        options={{ suppressMapOpenBlock: true }}
      >
        <Placemark
          modules={["geoObject.addon.balloon"]}
          geometry={[48.46264957390594, 135.08964050000003]}
          properties={{
            hintContent: 'Dr.Teeth',
            balloonContentBody: 'Dr.Teeth'
          }}
          options={{
            iconColor: '#000000'
          }}
        />
      </Map>
    </YMaps>
  );
}