/**
 * Компонент для отображения списка документов.
 *
 * Этот компонент перебирает массив `documents`, импортированный из '../../data/documents'.
 * и отображает каждый документ с помощью компонента Document.
 *
 * @component
 */

import { documents } from '../../data/documents';
import Document from '../elements/Document';

export default function Documents() {
  return (
    <div className="company-info">
      <ul className="company-info__list" aria-labelledby="registration">
        {documents.map(d => (
          <li className="company-info__item" key={d.id}>
            <Document doc={d} />
          </li>
        ))}
      </ul>
    </div>
  );
}