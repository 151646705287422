/**
 * Компонент кнопки "гамбургер" для управления открытием и закрытием меню.
 *
 * При клике на кнопку меняется состояние открытия меню.
 *
 * @component
 *
 * @remarks
 * В этом компоненте используются два контекста:
 * - `MenuBurgerContext` для управления состоянием открытия меню.
 * - `MenuBurgerLoopContext` для установления кнопки закрытия меню, что позволяет управлять фокусом.
 *
 * @see {@link MenuBurgerContext}
 * @see {@link MenuBurgerLoopContext}
 */

import { useContext, useEffect, useRef } from 'react';
import { MenuBurgerContext, MenuBurgerLoopContext } from '../../context/context';

export default function ButtonBurger() {
  const { isBurgerOpen, setIsBurgerOpen } = useContext(MenuBurgerContext);
  const { setBtnClose } = useContext(MenuBurgerLoopContext)
  let btnRef = useRef();

  const onClickBurger = () => setIsBurgerOpen(!isBurgerOpen);

  useEffect(() => setBtnClose(btnRef.current), [setBtnClose]);

  useEffect(() => {
    btnRef.current.setAttribute('aria-label', isBurgerOpen ? 'Закрыть меню.' : 'Меню.');
  }, [isBurgerOpen]);

  return (
    <button className="btn-burger" onClick={onClickBurger} ref={btnRef}>
      <span></span>
    </button>
  );
}