/**
 * Компонент `СontactsList` для отображения информации о контактах.
 *
 * Этот компонент использует данные из `contactsList`, импортированные из '../../data/contacts',
 * для отображения списка контактов. Каждый контакт отображается с помощью компонента `СontactsItem`.
 * Компонент также включает карту, реализованную через компонент `MapScheme`.
 *
 * @component
 */

import { contactsList } from '../../data/contacts';
import СontactsItem from '../elements/СontactsItem';
import MapScheme from '../elements/MapScheme';

export default function СontactsList() {
  return (
    <div className="container">
      <div className="section-hero__contacts contacts">
        <h1 className="section-hero__title" id="contacts">Контакты</h1>

        <ul className="contacts__list" aria-labelledby="contacts">
          {contactsList.map(i => (
            <li className="contacts__item" key={i.id}>
              <СontactsItem item={i} />              
            </li>
          ))}
        </ul>

        <MapScheme type="contacts__map" />
      </div>
    </div>
  );
}