import { v4 as uuidv4 } from 'uuid';
import lic1 from '../assets/img/documents/license-1.jpg';
import lic2 from '../assets/img/documents/license-2.jpg';
import lic3 from '../assets/img/documents/license-3.jpg';
import price from '../assets/docs/price.pdf';

export const registrations = [
  {
    name: 'ОГРН',
    number: '№1192724002844',
    issued: 'Выдан межрайонной ИФНС России № 15 по Хабаровску',
    dateOfIssue: '5 июня 2015 года',
    form: 'серия 78 № 009251551',
    id: uuidv4(),
  },
  {
    name: 'ИНН',
    number: '№2723203983',
    issued: 'Выдан межрайонной ИФНС России № 27 по Хабаровску',
    dateOfIssue: '4 июня 2015 года',
    form: 'серия 78 № 009251552',
    id: uuidv4(),
  },
];

export const documents = [
  {
    title: 'Медицинская лицензия №ЛО-27-01-002915',
    type: 'license',
    licenses: [lic1, lic2, lic3],
    id: uuidv4(),
  },
  {
    title: 'Информация о стоматологической клинике ООО «Dr.Teeth»',
    content: [
      'Адрес: 680007, г. Хабаровск, переулок Молдавский, 3а',
      'Генеральный директор: Тен Сергей Хончелович',
    ],
    id: uuidv4(),
  },
  {
    title: 'Перечень платных медицинских услуг',
    link: 'Скачать перечень платных медицинских услуг',
    url: price,
    id: uuidv4(),
  },
  {
    title: 'Правила внутреннего распорядка',
    link: 'Скачать правила внутреннего распорядка',
    url: '#',
    id: uuidv4(),
  },
  {
    title: 'Перечень жизненно необходимых и важнейших лекарственных препаратов для медицинского применения',
    link: 'Распоряжение Правительства Российской Федерации от 23 октября 2017 г.No 2323-р',
    url: 'http://static.government.ru/media/files/A5dTKAhmBi3JmTIgzDSOKC7G6VwDShxi.pdf',
    id: uuidv4(),
  },
  {
    title: 'Телефоны экстренных служб',
    content: [
      'Единая служба спасения - 112',
      'Пожарная (МЧС) - 101',
      'Полиция - 102',
      'Скорая помощь - 103',
      'Служба газа - 04',
    ],
    id: uuidv4(),
  },
];