/**
 * Компонент `License`, позволяющий отображать изображение документа и открывать всплывающее окно с детальной информацией.
 *
 * @component
 * @param {Object} props - Объект свойств компонента.
 * @param {string} props.image - URL изображения документа.
 * 
 * @remarks
 * Использует контекст `PopupContext` для управления всплывающим окном. При клике на изображение вызывается функция `onClickLicense`,
 * которая устанавливает всплывающее окно с типом 'document', открывает его и передает данные изображения.
 */

import { useContext } from 'react';
import { PopupContext } from '../../context/context';

export default function License({ image }) {
  const { setPopup } = useContext(PopupContext);

  const onClickLicense = (data) => setPopup({
    type: 'document',
    isOpen: true,
    data,
  });

  return (
    <button className="documents__img__wrapper" onClick={() => onClickLicense(image)} aria-label="Увеличить изображение.">
      <img
        src={image}
        alt="Фотография документа."
        className="documents__img"
      />
    </button>
  );
}