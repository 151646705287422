/**
 * Компонент страницы "Контакты" для отображения списка контактов и правил.
 *
 * @component
 */

import Hero from '../layout/Hero';
import СontactsList from '../blocks/СontactsList';
import СontactsRules from '../blocks/СontactsRules';

export default function СontactsPage() {
  document.title = 'Dr. Teeth | Контакты';
  
  return (
    <>
      <Hero height="auto">
        <СontactsList />
      </Hero>
      <СontactsRules />
    </>
  );
}