import { v4 as uuidv4 } from 'uuid';
import { phone } from './constants.js';

export const contacts = [
  {
    url: '#',
    type: 'address',
    text: 'Хабаровск, пер.Молдавский 3а',
    svgId: 'pin',
    id: uuidv4(),
  },
  {
    url: `tel:${phone.link}`,
    type: 'phone',
    text: phone.text,
    svgId: 'tel',
    id: uuidv4(),
  },
];

export const contactsList = [
  {
    title: 'Телефон для записи:',
    type: 'tel',
    text: phone.text,
    svgId: 'tel',
    id: uuidv4(),
  },
  {
    title: 'Режим работы:',
    text: 'Понедельник—пятница: 08:00 - 20:00',
    svgId: 'clock',
    id: uuidv4(),
  },
  {
    title: 'Адрес:',
    text: 'г.Хабаровск, переулок Молдавский 3а',
    svgId: 'pin',
    id: uuidv4(),
  },
];

export const contactsRules = [
  {
    title: 'График приёма граждан руководителем (генеральным директором):',
    director: 'Тен Сергей Хончелович',
    desc: `Понедельник — пятница с 10:00 - 18:00 (по предварительной записи), контактный номер ${phone.text}, электронная почта: `,
    link: true,
    id: uuidv4(),
  },
  {
    title: 'Правила записи на первичный приём/консультацию и обследование:',
    desc: `По предварительной записи, телефон ${phone.text}, в часы работы клиники.`,
    id: uuidv4(),
  },
  {
    title: 'Оставить отзыв:',
    desc: 'Оставить отзыв о работе клиники вы можете в письме на электронную почту: ',
    link: true,
    id: uuidv4(),
  },
];