/**
 * Компонент `AccordionItem` для отображения списка услуг.
 *
 * @component
 * @param {Object} data - Объект данных, содержащий информацию об услуге.
 * @param {string} data.name - Название услуги.
 * @param {Array} data.services - Массив объектов услуг.
 * 
 * Компонент использует состояние `isOpen` для отслеживания открытости панели.
 * При клике на кнопку-триггер состояние меняется, и соответственно открывается или закрывается панель.
 * Использует `useRef` для ссылки на элемент панели, чтобы управлять его видимостью.
 */

import { useRef, useState } from 'react';
import icons from '../../assets/img/icons.svg';
import Service from './Service';

export default function AccordionItem({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const panelRef = useRef();

  const onClickTrigger = () => {
    setIsOpen(!isOpen);
    !isOpen ? panelRef.current.removeAttribute("hidden") : panelRef.current.setAttribute("hidden", "");
  };

  return (
    <>
      <button className="accordion__summary" aria-expanded={isOpen} aria-controls="panel" onClick={onClickTrigger}>
        <h5 className="accordion__title">{data.name}</h5>

        <span className="accordion__icon">
          <svg>
            <use xlinkHref={`${icons}#icon-caret`}></use>
          </svg>
        </span>
      </button>

      <ul className="price-list" id="panel" ref={panelRef} hidden aria-label="Услуги">
        {data.services.map(s => (
          <li className="price-list__item" key={s.code}>
            <Service service={s} />
          </li>
        ))}
      </ul>
    </>
  );
}