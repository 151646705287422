/**
 * Компонент для отображения главной страницы сайта.
 *
 * @component
 */

import Hero from '../layout/Hero';
import Main from '../blocks/Main';
import MainContent from '../blocks/MainContent';

export default function MainPage() {
  document.title = 'Dr. Teeth';

  return (
    <>
      <Hero>
        <Main />
      </Hero>
      <MainContent />
    </>
  );
}