/**
 * Компонент `DoctorCard` отображает информацию о враче.
 *
 * Этот компонент используется для отображения краткой информации о враче, включая его фотографию, имя, должность и ссылку на подробную информацию.
 * При клике на ссылку происходит прокрутка страницы вверх и переход на страницу с полной информацией о враче.
 *
 * @component
 * @param {Object} doctor - Объект с информацией о враче.
 * @param {string} doctor.img - URL изображения врача.
 * @param {string} doctor.surname - Фамилия врача.
 * @param {string} doctor.name - Имя врача.
 * @param {string} doctor.url - URL страницы с полной информацией о враче.
 */

import { Link } from 'react-router-dom';

export default function DoctorCard({ doctor}) {
  const onClickMore = (e) => {
    e.stopPropagation();
    window.scrollTo(0, 0);
  };
  
  return (
    <div className="doctor-card">
      <div className="doctor-card__img-box">
        <img src={doctor.img} alt={doctor.alt} className="doctor-card__img" />
      </div>

      <h3 className="doctor-card__name">
        <span>{doctor.surname}</span>
        <span>{doctor.name}</span>
      </h3>
      
      <p className="doctor-card__specialty">{doctor.specialty}</p>
      <Link
        to={`/doctors/${doctor.url}`}
        className="doctor-card__link link-text"
        onClick={onClickMore}
        aria-label={`Врач ${doctor.surname} ${doctor.name} подробнее`}
      >
        Подробнее
      </Link>
    </div>
  );
}
