/**
 * Компонент `Logo` для отображения логотипа сайта.
 *
 * Этот компонент использует контекст `MenuBurgerContext` для управления состоянием меню.
 * При клике на логотип происходит прокрутка страницы в начало и закрытие меню.
 * В зависимости от местоположения компонента (`location`) отображается разный логотип.
 *
 * @component
 * @param {string} location - Строка, влияющая на стилизацию.
 */

import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MenuBurgerContext } from '../../context/context';
import logo from '../../assets/img/logo.png';
import logoDark from '../../assets/img/logo-dark.png';

export default function Logo({ location }) {
  const { setIsBurgerOpen } = useContext(MenuBurgerContext);

  const onClickLogo = (e) => {
    e.stopPropagation();
    window.scrollTo(0, 0);
    setIsBurgerOpen(false);
  };
  
  return (
    <Link to="/" className={`${location}__logo-box`} onClick={onClickLogo}>
      <img
        src={logo}
        alt="Логотип Доктор Тиз."
        className={`${location}__logo-img`}
      />
      
      {location === 'header' && <img
        src={logoDark}
        alt="Логотип Доктор Тиз."
        className={`${location}__logo-img ${location}__logo-img--dark`}
      />}
    </Link>
  );
}