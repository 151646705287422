/**
 * Компонент модального окна для отображения всплывающих уведомлений или документов.
 *
 * Этот компонент использует контекст `PopupContext` для управления состоянием модального окна,
 * включая его открытие/закрытие и тип содержимого (телефонный номер или документ).
 *
 * @component
 * @remarks
 * Компонент использует `useContext` для доступа к контексту `PopupContext`, который содержит информацию о текущем всплывающем окне,
 * включая его открытость (`isOpen`), тип содержимого (`type`), и данные для отображения (например, изображение документа).
 *
 * @see {@link PopupContext} для более подробной информации о контексте всплывающих окон.
 */

import { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { PopupContext } from '../../context/context';
import { phone } from '../../data/constants';

export default function PopupModal() {
  const dialogRef = useRef(null);
  const { popup, setPopup } = useContext(PopupContext);

  const onClickCloseBtn = () => {
    popup.prevFocus?.focus();

    setPopup(prev => ({
      ...prev,
      isOpen: false,
      prevFocus: null,
    }));
  };

  return (
    <Modal
      open={popup.isOpen}
      onClose={onClickCloseBtn}
      ref={dialogRef}
      center
      classNames={{
        modal: `popup-modal ${popup.type === 'document' && 'popup-modal--document'}`,
        closeButton: 'popup-modal__close',
        overlay: 'popup-modal__overlay'
      }}
      closeIcon={(<span className="visually-hidden">Закрыть</span>)}
      aria-labelledby="popup-modal__title_id"
    >
      {popup.type === 'appointment' ? (
        <>
          <h2 className="popup-modal__title" id="popup-modal__title_id">Номер телефона для записи:</h2>
          <Link to={`tel:${phone.link}`} className="popup-modal__tel ">{phone.text}</Link>
          <p className="popup-modal__subtitle">Режим работы стоматологии «Dr.Teeth»:</p>
          <p className="popup-modal__content">Понедельник—воскресенье: 08:00 - 20:00 </p>
        </>
      ) : (
        <img src={popup.data} alt="Фотография документа." className="popup-moodal__document" />
      )}
    </Modal>
  );
}