/**
 * Компонент `СontactsRule` для отображения дополнительной информации в разделе Контакты.
 *
 * @component
 * @param {Object} rule - Объект с информацией.
 * @param {string} rule.title - Заголовок.
 * @param {string} rule.director - ФИО директора клиники (опционально).
 * @param {string} rule.desc - Описание.
 * @param {boolean} rule.link - Флаг, указывающий на наличие ссылки на электронную почту.
 */

import { Link } from 'react-router-dom';
import { email } from '../../data/constants';

export default function СontactsRule({ rule }) {
  return (
    <div className="container">
      <div className="contact-page__section-title">{rule.title}</div>

      <div className="contact-page__section-desc">
        {rule.director && <p>{rule.director}</p>}
        {rule.desc}
        {rule.link && <Link to={`mailto:${email}`}>{email}</Link>}
      </div>
    </div>
  );
}