/**
 * Компонент `Contacts` отображает список контактов.
 *
 * В зависимости от переданного параметра `location`, компонент может отображать контакты в разных цветах и порядке отображения.
 *
 * @component
 * @param {Object} props - Объект свойств компонента.
 * @param {string} props.location - Расположение, в котором отображаются контакты.
 */

import { contacts } from '../../data/contacts';
import Contact from './Contact';

export default function Contacts({ location }) {
  let colorList, contactsUsed;

  if (location === 'header') {
    colorList = 'blue';
    contactsUsed = Object.assign([], contacts).reverse();
  } else {
    colorList = 'gold';
    contactsUsed = contacts;
  }

  return (
    <div className={`${location}__contacts`}>
      <ul className={`contacts-list contacts-list--${colorList}`} aria-label="Контакты">
        {contactsUsed.map(c => (
          <li className="contacts-list__item" key={c.id}>
            <Contact contact={c} />            
          </li>
        ))}
      </ul>
    </div>
  );
}