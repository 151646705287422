/**
 * Компонент `Doctor` отображает информацию о враче, включая его фотографию, имя, специальность, образование и дипломы.
 *
 * @component
 * @param {Object} doctor - Объект с информацией о враче.
 * @param {string} doctor.img - Изображение врача.
 * @param {string} doctor.surname - Фамилия врача.
 * @param {string} doctor.name - Имя врача.
 * @param {string} doctor.specialtyFull - Полное название специальности врача.
 * @param {string} doctor.education - Образование врача.
 * @param {Array} doctor.diplomas - Массив объектов с информацией о дипломах и сертификатах врача.
 *
 * @remarks
 * Использует контекст `PopupContext` для управления всплывающими окнами.
 * Использует `useRef` для хранения ссылки на элементы дипломов, что позволяет управлять фокусом при открытии всплывающего окна с документом.
 * В компоненте используются дополнительные компоненты `Breadcrumbs`, `ButtonAppointment` для отображения навигационных хлебных крошек и кнопки для записи на прием соответственно.
 */

import { useContext, useRef } from 'react';
import { PopupContext } from '../../context/context';
import Breadcrumbs from '../ui/Breadcrumbs';
import ButtonAppointment from '../ui/ButtonAppointment';
import { phone } from '../../data/constants';

export default function Doctor({ doctor }) {
  const { setPopup } = useContext(PopupContext);
  const diplomasRef = useRef(null);

  /**
   * Инициализирует и возвращает Map для хранения ссылок на элементы списка дипломов.
   *
   * @returns {Map} Map с ключами id дипломов и значениями ссылками на элементы списка.
   */
  function getMap() {
    if (!diplomasRef.current) diplomasRef.current = new Map();
    return diplomasRef.current;
  }

  /**
   * Обработчик клика по диплому, открывает модальное окно с изображением диплома.
   *
   * @param {Object} data - Объект с информацией о дипломе.
   * @param {string} data.img - URL изображение диплома.
   * @param {string} data.id - Уникальный идентификатор диплома.
   */
  const onClickDiploma = (data) => setPopup({
    type: 'document',
    isOpen: true,
    data: data.img,
    prevFocus: diplomasRef.current.get(data.id).firstChild,
  });

  return (
    <div className="container">
      <Breadcrumbs />
      
      <div className="doctor-info">
        <div className="doctor-info__wrapper">
          <div className="doctor-info__column doctor-info__column--left">
            <div className="doctor-info__img-box">
              <img src={doctor.img} alt={doctor.alt} className="doctor-info__img" />
            </div>
          </div>

          <div className="doctor-info__column">
            <div className="doctor-info__content">
              <h1 className="doctor-info__name">{doctor.surname} {doctor.name}</h1>
              <p className="doctor-info__specialty">{doctor.specialtyFull}</p>
              <p className="doctor-info__education">Образование: {doctor.education}</p>

              <div className="doctor-info__documents documents">
                <h5 className="documents__title" id="diplomas">Дипломы и сертификаты:</h5>

                <ul className="documents__list" aria-labelledby="diplomas">
                  {doctor.diplomas && doctor.diplomas.map(d => (
                    <li
                      className="documents__item"
                      key={d.id}
                      ref={(node) => {
                        const map = getMap();
                        node ? map.set(d.id, node) : map.delete(d.id);
                      }}
                    >
                      <button className="documents__img__wrapper" onClick={() => onClickDiploma(d)} aria-label="Увеличить изображение.">
                        <img src={d.img} alt={d.alt} className="documents__img" />
                      </button>
                    </li>
                  ))}
                </ul>

              </div>
            </div>
          </div>
        </div>

        <div className="doctor-info__wrapper">
          <div className="doctor-info__column doctor-info__column--left">
            <a href={`tel:${phone.link}`} className="doctor-info__btn-cta btn btn--gold">Записаться</a>
            <ButtonAppointment type="doctor-info__popup-trigger" />
          </div>

          <div className="doctor-info__column">
            <div className="doctor-info__schedule">
              <p>График приема: по предварительной записи</p>
              <p>Телефон для записи: {phone.text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}