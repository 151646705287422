/**
 * Компонент `Vacancy` для отображения информации о вакансии.
 *
 * Этот компонент использует `Link` из `react-router-dom` для навигации на страницу с деталями вакансии.
 * Отображает заголовок и описание вакансии, а также ссылку для перехода к деталям.
 *
 * @component
 * @param {Object} vacancy - Объект, содержащий информацию о вакансии.
 * @param {string} vacancy.title - Заголовок вакансии.
 * @param {string} vacancy.desc - Описание вакансии.
 * @param {string} vacancy.url - URL для перехода к деталям вакансии.
 */

import { Link } from 'react-router-dom';

export default function Vacancy({ vacancy }) {
  return (
    <div className="container">
      <h4 className="vacancy_title">{vacancy.title}</h4>
      <p className="vacancy__desc">{vacancy.desc}</p>
      <Link to={vacancy.url} className="vacancy__link link-text">Подробнее</Link>
    </div>
  );
}