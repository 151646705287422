import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { MenuBurgerContext, PopupContext } from './context/context';
import './App.scss';
import Footer from './components/layout/Footer';
import PopupModal from './components/elements/PopupModal';

export default function App() {
  const [popup, setPopup] = useState({
    type: '',
    isOpen: false,
    data: null,
    prevFocus: null,
  });

  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const calculateMenuHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);

  useEffect(() => {
    document.documentElement.style.setProperty('--hero-mobile-height', `${window.innerHeight}px`);
    calculateMenuHeight();
    
    window.addEventListener('resize', calculateMenuHeight);
    return () => window.removeEventListener('resize', calculateMenuHeight);
  }, []);

  return (
    <MenuBurgerContext.Provider value={{ isBurgerOpen, setIsBurgerOpen }}>
      <PopupContext.Provider value={{ popup, setPopup }}>
        <div className={`App${isBurgerOpen ? ' main-nav-open' : ''}`}>
          <Outlet />
          <Footer />
          <PopupModal />
        </div>
      </PopupContext.Provider>
    </MenuBurgerContext.Provider>
  );
}