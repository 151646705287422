export const doctors = await fetch(`${process.env.REACT_APP_BACKEND}api/doctors?populate[diplomas][populate][0]=img&populate=img`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
})
  .then((response) => response.json())
  .then((data) => {
    return data.data.map(item => {
      return {
        surname: item.attributes.surname,
        name: item.attributes.name,
        img: process.env.REACT_APP_BACKEND + item.attributes.img.data.attributes.url.slice(1),
        alt: item.attributes.img.data.attributes.alternativeText,
        specialty: item.attributes.specialty,
        specialtyFull: item.attributes.specialtyFull,
        education: item.attributes.education,
        diplomas: item.attributes.diplomas.data.map(diploma => ({
          img: process.env.REACT_APP_BACKEND + diploma.attributes.img.data.attributes.url.slice(1),
          alt: diploma.attributes.img.data.attributes.alternativeText,
          id: diploma.attributes.uuid,
        })),
        url: item.attributes.url,
        id: item.attributes.uuid,
      };
    });
  });