export const services = await fetch(`${process.env.REACT_APP_BACKEND}api/dental-sections?populate=*`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
})
  .then((response) => response.json())
  .then((data) => {
    return data.data.map(item => {
      return {
        name: item.attributes.name,
        id: item.attributes.uuid,
        services: item.attributes.services.data.map(service => ({
          name: service.attributes.name,
          price: service.attributes.price,
          code: service.attributes.code,
        })),
      };
    });
  });