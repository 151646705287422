/**
 * Компонент страницы ошибки.
 *
 * Этот компонент отображает сообщение об ошибке, когда пользователь переходит на несуществующую страницу.
 * Он использует компонент `Hero` для отображения сообщения об ошибке в центре страницы.
 *
 * @component
 */

import Hero from '../layout/Hero';

export default function ErrorPage() {
  document.title = 'Ошибка!';

  return (
    <Hero>
      <div className="error">Страница не найдена!</div>
    </Hero>
  );
}