/**
 * Компонент `Feature` отображает информацию о преимуществе.
 *
 * Этот компонент отображает иконку и описание преимущества, используя данные, переданные через проп `feature`.
 *
 * @component
 * @param {Object} feature - Объект, содержащий информацию о преимуществе.
 * @param {string} feature.svgId - Идентификатор SVG иконки, который используется для выбора иконки из `icons.svg`.
 * @param {string} feature.title - Заголовок преимущества.
 * @param {string} feature.desc - Описание преимущества.
 */

import icons from "../../assets/img/icons.svg";

export default function Feature({ feature }) {
  return (
    <>
      <div className="feature__icon-box">
        <svg className="feature__icon">
          <use xlinkHref={`${icons}#icon-tooth-${feature.svgId}`}></use>
        </svg>
      </div>
      
      <div className="feature__content">
        <h4 className="feature__title">{feature.title}</h4>
        <p className="feature__desc">{feature.desc}</p>
      </div>
    </>
  );
}