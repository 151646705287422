/**
 * Компонент навигационной панели.
 *
 * Этот компонент использует контекст `MenuBurgerContext` и `MenuBurgerLoopContext` для управления состоянием открытия и закрытия меню.
 * Он также содержит список ссылок навигации, полученных из `menu`, и компонент `Contacts`.
 * При клике на ссылку выполняется прокрутка страницы вверх и закрывается меню.
 *
 * @component
 */

import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MenuBurgerContext, MenuBurgerLoopContext } from '../../context/context';
import { menu } from '../../data/menu';
import Contacts from './Contacts';
import { phone } from '../../data/constants';

export default function Nav() {
  const { setIsBurgerOpen } = useContext(MenuBurgerContext);
  const { btnClose } = useContext(MenuBurgerLoopContext)

  const onClickLink = (e) => {
    e.stopPropagation();
    window.scrollTo(0, 0);
    setIsBurgerOpen(false);
  };

  const onBlurCall = () => btnClose.focus();

  return (
    <nav className="main-nav">
      <ul className="main-nav__list">
        {menu.map(m => (
          <li className="main-nav__item" key={m.id}>
            <NavLink to={m.url} className="main-nav__link" onClick={onClickLink}>{m.name}</NavLink>
          </li>
        ))}
      </ul>

      <Contacts location="main-nav" />
      <Link to={`tel:${phone.link}`} className="main-nav__btn btn btn--gold" onBlur={onBlurCall}>Позвонить адмнистратору</Link>
    </nav>
  );
}