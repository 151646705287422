/**
 * Компонент для отображения информации про отдельного врача.
 *
 * Этот компонент использует хук `useParams` для получения параметра `url` из URL,
 * который затем используется для поиска соответствующего врача в массиве `doctors`.
 * После нахождения врача, информация о нем отображается с помощью компонента `Doctor`.
 *
 * @component
 * @param {string} url - Параметр URL, используемый для поиска врача.
 *
 * @see {@link https://dev.to/javila35/react-router-hook-useparam-now-w-typescript-m93 | Использование useParams с TypeScript}
 */

import { useParams } from 'react-router-dom';
import { doctors } from '../../data/doctors';
import Hero from '../layout/Hero';
import Doctor from '../blocks/Doctor';

export default function DoctorPage() {
  document.title = 'Dr. Teeth | Специалисты';
  
  const { url } = useParams();  
  const doctor = doctors.find(d => d.url === url);

  return (
    <Hero height="auto" type="doctors">
      <Doctor doctor={doctor} />
    </Hero> 
  );
}