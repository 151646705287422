/**
 * Компонент `Star` для отображения иконки звезды в различных состояниях: полная, половинная, пустая.
 *
 * @component
 * @param {string} fullness - Состояние звезды: 'full' для полной, 'half' для половинной, 'empty' для пустой.
 */

export default function Star({ fullness }) {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      {fullness === 'full' && <path d="M8 11.4783L12.9467 15L11.0533 9.31304L16 5.86957H9.93333L8 0L6.06667 5.86957H0L4.94667 9.31304L3.05333 15L8 11.4783Z" fill="#272727" />}

      {fullness === 'half' && <path d="M16 5.71579L10.248 5.22632L8 0L5.752 5.23421L0 5.71579L4.368 9.45L3.056 15L8 12.0553L12.944 15L11.64 9.45L16 5.71579ZM8 10.5789V3.23684L9.368 6.42632L12.872 6.72632L10.216 9L11.016 12.3789L8 10.5789Z" fill="#272727" />}

      {fullness === 'empty' && <path d="M8 11.4783L12.9467 15L11.0533 9.31304L16 5.86957H9.93333L8 0L6.06667 5.86957H0L4.94667 9.31304L3.05333 15L8 11.4783Z" fill="none" stroke="#272727" />}
    </svg>
  );
}