/**
 * Компонент `SwiperSlide` используется для создания слайдов внутри слайдера Swiper.
 *
 * @component
 * @param {ReactNode} children - Содержимое слайда.
 * @param {Object} rest - Дополнительные свойства для элемента `swiper-slide`.
 */

export function SwiperSlide({ children, ...rest }) {
  return (
    <swiper-slide {...rest}>
      {children}
    </swiper-slide>
  );
}