import { v4 as uuidv4 } from 'uuid';

export const features = [
  {
    title: 'Специализируемся на имплантах',
    desc: 'Зубная фея оставит вам самые приятные впечатления от лечения',
    svgId: 'screw',
    id: uuidv4(),
  },
  {
    title: 'Проводим сложные удаления',
    desc: 'Наши хирурги совмещают работу в челюстно-лицевом стационаре и клинике Dr.Teeth',
    svgId: 'wave',
    id: uuidv4(),
  },
  {
    title: 'Лечим под наркозом',
    desc: 'Комфортное лечение под наркозом для взрослых и детей',
    svgId: 'star',
    id: uuidv4(),
  },
];