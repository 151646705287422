/**
 * Компонент для отображения документа.
 *
 * Отображает информацию о документе, включая заголовок, тип документа и его содержимое.
 * В зависимости от типа документа (`doc.type`) отображает либо список страниц медицинской лицензии, либо текстовое содержимое и ссылку на документ.
 *
 * @component
 * @param {Object} doc - Объект документа, содержащий информацию о документе.
 * @param {string} doc.id - Уникальный идентификатор документа.
 * @param {string} doc.title - Заголовок документа.
 * @param {string} [doc.type] - Тип документа (опционально).
 * @param {Array} [doc.licenses] - Массив лицензий, если документ имеет тип.
 * @param {Array} [doc.content] - Массив содержимого документа, если документ не имеет типа.
 * @param {string} [doc.link] - Ссылка на документ, если предоставлена.
 * @param {string} [doc.url] - URL для скачивания документа, если предоставлена ссылка.
 */

import { v4 as uuidv4 } from 'uuid';
import License from './License';

export default function Document({ doc }) {
  return (
    <div className="container">
      <h4 className="company-info__item-title" id={doc.id}>{doc.title}</h4>

      {doc.type ? (

        <div className="documents">
          <ul className="documents__list" aria-labelledby={doc.id}>
            {doc.licenses.map(l => (
              <li className="documents__item" key={uuidv4()}>
                <License image={l} />
              </li>
            ))}
          </ul>
        </div>

      ) : (

        <p className="company-info__item-text">
          {doc.content && doc.content.map(c => <span key={uuidv4()}>{c}</span>)}
          {doc.link && <a href={doc.url} download target="_blank" rel="noopener noreferrer">{doc.link}</a>}
        </p>

      )}
    </div>
  );
}