/**
 * Компонент `Price` отображает список услуг в виде аккордеона.
 *
 * @component
 * @param {Object[]} services - Массив объектов услуг, полученных из '../../data/services'.
 * @param {string} services[].id - Уникальный идентификатор услуги.
 *
 * Каждый элемент списка представлен компонентом `AccordionItem`.
 * Компонент также предоставляет ссылку для скачивания полного прайса в формате PDF.
 */

import { services } from '../../data/services';
import AccordionItem from '../elements/AccordionItem';
import price from '../../assets/docs/price.pdf';

export default function Price() {
  return (
    <section className="section section--price">
      <div className="container">
        <div className="section__head">
          <h2 className="heading-secondary" id="prices">Цены на популярные услуги</h2>
          <a href={price} className="link-text" download>Скачать полный прайс в PDF</a>
        </div>

        <div className="accordion">
          <ul className="accordion__list" aria-labelledby="prices">
            {services.map(s => (
              <li className="accordion__item" key={s.id}>
                <AccordionItem data={s} />              
              </li>
            ))}            
          </ul>
        </div>
      </div>
    </section>
  );
}