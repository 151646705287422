/**
 * Компонент для отображения списка вакансий.
 *
 * Этот компонент использует данные из `vacancies`, импортированные из '../../data/vacancies',
 * для отображения каждой вакансии с помощью компонента `Vacancy`.
 *
 * @component
 */

import { vacancies } from '../../data/vacancies';
import Vacancy from '../elements/Vacancy';

export default function Vacancies() {
  return (
    <div className="vacancies">
      <div className="container">
        <h2 className="vacancies__heading" id="vacancies">Вакансии «Dr.Teeth»</h2>
      </div>

      <ul className="vacancies__list" aria-labelledby="vacancies">
        {vacancies.map(v => (
          <li className="vacancies__item vacancy" key={v.id}>
            <Vacancy vacancy={v} />            
          </li>
        ))}
      </ul>
    </div>
  );
}